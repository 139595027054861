import { Redirect } from '@reach/router'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { filter, find, forEach, get, isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { color, space } from 'styled-system'
import { useImmer } from 'use-immer'

import Info from './lib/info'
import { ReactComponent as Logo } from './lib/logo.svg'
import config from '../.lyzer/config.json'
import Formalyzer, { buildDefaultValue, validateGadgets } from '../formalyzer'
import A from '../ui/a'
import Button from '../ui/button'
import { useSnacks } from '../ui/snacks'

const emailRegex = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/

function validate (gadgets, value) {
  const required = filter(gadgets, 'required')
  const defaults = buildDefaultValue(gadgets)
  const email = get(value, 'email')
  const errors = []
  // Email validation
  // TODO: Move this to formalyzer once text inputs have a type
  if (!isEmpty(email)) {
    if (!emailRegex.test(email)) {
      errors.push({
        formKey: 'email',
        message: 'You must enter a valid email address'
      })
    }
  }
  forEach(required, gadget => {
    if (gadget.formKey) {
      if (get(value, gadget.formKey) === get(defaults, gadget.formKey)) {
        errors.push({
          formKey: gadget.formKey,
          message: `${gadget.label || gadget.formKey} is required.`
        })
      }
    }
  })
  errors.push(...validateGadgets(gadgets, value))
  return errors.length ? errors : null
}

export default ({ route, navigate, data, id, token }) => {
  const form = find(config.forms, form => form.meta.route === route)
  if (!form) return <Redirect noThrow to='/forms' />
  return (
    <FormPage
      route={route}
      navigate={navigate}
      data={data}
      id={id}
      token={token}
      form={form}
    />
  )
}

export const FormPage = ({ route, navigate, data, id, token, form }) => {
  const [shouldValidate, setShouldValidate] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const snack = useSnacks()
  const [errors, setErrors] = useState(null)
  const [value, updateValue] = useImmer(data || buildDefaultValue(form.gadgets))
  useEffect(() => {
    if (shouldValidate) setErrors(validate(form.gadgets, value))
  }, [form.gadgets, shouldValidate, value])
  const needsPayment = form.process.paymentRequired && !id
  return (
    <Wrapper>
      <Flex>
        <Info />
        <Logo />
      </Flex>
      <Title mt={2}>{form.meta.name}</Title>
      {form.meta.pdfLink && (
        <A my={1} href={form.meta.pdfLink}>
          Click here to download the pdf
        </A>
      )}
      <Box>
        <Formalyzer
          logic={form.logic}
          errorKeys={map(errors, 'formKey')}
          gadgets={form.gadgets}
          value={value}
          updateValue={updateValue}
        />
      </Box>
      <RightArea>
        {errors && (
          <ErrorBox px={4} py={3}>
            <ErrorTitle>
              Fix the following before re-submitting the form:
            </ErrorTitle>
            <ul>
              {errors.map(error => (
                <li>{error.message}</li>
              ))}
            </ul>
          </ErrorBox>
        )}
        <SubmitButton
          disabled={errors || submitting}
          onClick={() => {
            if (!route) return
            const errors = validate(form.gadgets, value)
            if (errors) {
              setShouldValidate(true)
              setErrors(errors)
              return
            }
            setSubmitting(true)
            const promise = id
              ? axios.put(
                `/api/${route}/${id}`,
                { form: value },
                { headers: { 'x-formal-email-token': token } }
              )
              : axios.post(`/api/${route}`, { form: value })
            promise
              .then(resp => {
                const path = needsPayment
                  ? `/forms/${route}/${resp.data.data.id}/payment`
                  : `/forms/${route}/confirmation`
                navigate(path)
              })
              .catch(error => {
                setSubmitting(false)
                if (error.response.status === 422) {
                  const newErrors = []
                  forEach(error.response.data.errors, (errors, formKey) => {
                    const gadget = find(form.gadgets, { formKey }) || {}
                    const label = gadget.label || formKey
                    forEach(errors, errorMessage => {
                      if (errorMessage === "can't be blank") {
                        newErrors.push({
                          formKey,
                          message: `${label} is required.`
                        })
                        // uncomment this after we start validating emails client side
                        // } else if (errorMessage === 'is not valid') {
                        //   newErrors.push({
                        //     formKey,
                        //     message: `${label} is not valid.`
                        //   })
                      } else {
                        newErrors.push({
                          formKey,
                          message: `${label} has a problem.`
                        })
                        Sentry.captureException(
                          new Error(
                            `Unknown errorMessage "${errorMessage}" received from server when submitting form "${route}"`
                          )
                        )
                      }
                    })
                  })
                  setShouldValidate(true)
                  setErrors(newErrors)
                } else {
                  snack.create(
                    'An unexpected error has occurred. Please try again.'
                  )
                  Sentry.captureException(error)
                }
              })
          }}
        >
          {submitting ? (
            <span>
              Submitting <Loader />
            </span>
          ) : needsPayment ? (
            'Continue and Pay'
          ) : (
            'Submit'
          )}
        </SubmitButton>
      </RightArea>
    </Wrapper>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px dashed;
  border-radius: 20px;
  animation: ${rotate} 2s linear infinite;
  position: absolute;
  margin-left: 8px;
  margin-top: -2px;
`

const Wrapper = styled.div`
  @media (min-width: 950px) {
    max-width: 768px;
  }
  margin: 0 auto;
  margin-top: 32px;
  width: 100%;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Box = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 193px);
  ${color}
  ${space}
`
Box.defaultProps = { p: 4, bg: 'lightgray' }

const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  ${space}
`

const RightArea = styled.div`
  @media (min-width: 950px) {
    position: fixed;
    bottom: 16px;
    right: 16px;
    min-width: calc((100vw - 768px) / 2 - 32px);
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
`

const ErrorBox = styled.div`
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 10px;
  ${space}
  &:before {
    content: '';
    width: 30px;
    height: 30px;
    background: white;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.15);
    display: block;
    position: absolute;
    bottom: -15px;
    left: 25%;
    transform: rotate(45deg);
  }
`

const ErrorTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`
