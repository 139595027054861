import styled from 'styled-components'
import { color, space, width } from 'styled-system'

const Button = styled.button`
  height: 42px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  user-select: none;
  ${color}
  ${space}
  ${width}
  background: ${p =>
    p.active ? p.theme.colors.hoverGreen : p.theme.colors.green};
  &:hover {
    background: ${p => p.theme.colors.hoverGreen};
  }

  &:disabled {
    background: #c5cacf;
    color: #6a6a6a;
    cursor: not-allowed;
  }
`
Button.defaultProps = { color: 'white' }

export default Button
