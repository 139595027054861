import * as Sentry from '@sentry/browser'
import React from 'react'
import styled from 'styled-components'
import { color } from 'styled-system'

import _Button from './ui/button'

export default class RootErrorBoundary extends React.Component {
  state = { error: null, eventId: null }

  componentDidCatch (error, errorInfo) {
    this.setState({ error })
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render () {
    if (!this.state.error) return this.props.children
    return (
      <Container>
        <Wrapper>
          <Box>
            <Big>Something Went Wrong</Big>
            <Text>
              We've got our team working diligently to fix this problem!
            </Text>
            <Button
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report feedback
            </Button>
          </Box>
        </Wrapper>
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  ${color}
`
Container.defaultProps = { bg: 'green' }

const Wrapper = styled.div`
  padding-top: 128px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 16px;
  height: 435px;
  width: 768px;
  max-width: 100vw;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  ${color}
`
Box.defaultProps = { bg: 'white' }

const Big = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin: 32px auto;
`

const Text = styled.div`
  font-size: 14px;
  margin-bottom: 48px;
`

const Button = styled(_Button)`
  width: 304px;
`
