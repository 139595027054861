import React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

const Container = styled.div`
  padding-bottom: 64px;
`

const Label = styled.h1`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  ${color}
  ${space}
`
Label.defaultProps = { mt: 0, mb: 1, p: 0, color: 'darkgray' }

export const Edit = ({ children }) => <div>{children}</div>

export const Assembler = ({ label, description, component }) => (
  <Container>
    <Label>{label}</Label>
    {component}
  </Container>
)
