import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { isEqual, last } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import { useImmer } from 'use-immer'

import Modal from './modal'
import Formalyzer from '../formalyzer'
import * as Date from '../formalyzer/gadgets/date'
import { useSnacks } from '../ui/snacks'
import Textarea from '../ui/textarea'
import { Popover, useOnOutsideClick } from '../ui/popover'

const useLoadDocument = (route, id) => {
  const [{ loading, data, isDataUpdated }, update] = useImmer({
    loading: true,
    data: null,
    isDataUpdated: false
  })
  useEffect(() => {
    axios
      .get(`/api/${route}/${id}`)
      .then(resp => {
        update(draft => {
          draft.data = resp.data.data
          draft.loading = false
        })
      })
      .catch(error => {
        Sentry.captureException(error)
        // what should we actually do on an error?
        update(draft => {
          draft.loading = false
        })
      })
  }, [id, route, update])
  const updateData = fn => {
    update(draft => {
      fn(draft.data)
      draft.isDataUpdated = !isEqual(draft.data, data)
    })
  }
  return [loading, data, isDataUpdated, updateData]
}

const useLoadProgress = (route, id) => {
  const [[loading, document], setState] = useState([true, null])
  useEffect(() => {
    axios
      .get(`/api/${route}/${id}/progress`)
      .then(resp => {
        setState([false, resp.data.data])
      })
      .catch(error => {
        Sentry.captureException(error)
        // what should we actually do on an error?
        setState([false, null])
      })
  }, [id, route])
  return [loading, document]
}

const Content = styled.div`
  background: white;
  color: black;
  position: relative;
  padding: 8px;
`

const DocumentViewHeader = ({
  takeAction,
  isDataUpdated,
  record,
  currentAction,
  sendEmail,
  saveRecord
}) => {
  const ref = useRef()
  const [openAction, setOpenAction] = useState(null)
  useOnOutsideClick(ref, () => setOpenAction(null), openAction)
  const [date, setDate] = useState(null)
  const [emailBody, setEmailBody] = useState('')
  // TODO:: Show history here somewhere
  return (
    <ActionsHeader>
      {currentAction && currentAction.dateRequired && (
        <PopoverButton>
          <ActionButton
            onClick={() =>
              setOpenAction(openAction === 'action' ? null : 'action')
            }
          >
            {currentAction.dialogButtonText}
          </ActionButton>
          {openAction === 'action' && (
            <Popover ref={ref}>
              <Content>
                {currentAction.datePickerLabel}
                <Date.Edit
                  value={date}
                  onChange={setDate}
                  config={{ filterKey: currentAction.datePickerFilterKey }}
                />
                <ApproveButton
                  onClick={() => {
                    if (!date) return
                    setOpenAction(null)
                    takeAction(date)
                  }}
                >
                  {currentAction.submitButtonText}
                </ApproveButton>
              </Content>
            </Popover>
          )}
        </PopoverButton>
      )}
      {currentAction && !currentAction.dateRequired && (
        <ActionButton
          onClick={() => {
            setOpenAction(null)
            takeAction()
          }}
        >
          {currentAction.dialogButtonText}
        </ActionButton>
      )}
      <PopoverButton>
        <ActionButton
          onClick={() =>
            setOpenAction(openAction === 'changes' ? null : 'changes')
          }
        >
          Send Email to Requestor
        </ActionButton>
        {openAction === 'changes' && (
          <Popover ref={ref}>
            <Content>
              Body
              <Textarea onChange={setEmailBody} value={emailBody} />
              <ApproveButton
                onClick={() => {
                  setOpenAction(null)
                  sendEmail(emailBody)
                  setEmailBody('')
                }}
              >
                Send
              </ApproveButton>
            </Content>
          </Popover>
        )}
      </PopoverButton>
      {isDataUpdated && (
        <ActionButton onClick={() => saveRecord(record)}>
          Save Changes
        </ActionButton>
      )}
    </ActionsHeader>
  )
}

const DocumentView = ({ activeForm, closeView, updateDate, route, id }) => {
  const [loading, data, isDataUpdated, updateData] = useLoadDocument(route, id)
  const [loading2, progress] = useLoadProgress(route, id)
  const snack = useSnacks()
  if (loading || loading2) return null
  const currentAction = getCurrentAction(activeForm, progress)
  const logError = error => {
    snack.create('An unexpected error has occurred. Please try again.')
    Sentry.captureException(error)
  }
  const takeAction = date =>
    axios
      .post(
        `/api/${route}/${id}/${currentAction.urlSuffix}`,
        date ? { date } : undefined
      )
      .then(() => {
        // TODO:: Send this back as part of the request
        // for now we'll need to refresh...
        // updateData(draft => {
        //   draft.date_to_appear = date // TODO:: update correct data here, maybe just refetch? or splat what server sends back?
        // })
        snack.create(currentAction.successMsg)
        // updateDate(id, date)
        closeView()
      })
      .catch(logError)
  const sendEmail = body =>
    axios
      .post(`/api/${route}/${id}/request`, { body })
      .then(() => snack.create('Message sent.'))
      .catch(logError)
  const saveRecord = record => {
    axios
      .put(`/api/${route}/${record.id}`, { id: record.id, form: record })
      .then(() => snack.create('Changes saved!'))
      .catch(logError)
  }
  const headerProps = {
    currentAction,
    takeAction,
    record: data,
    isDataUpdated,
    sendEmail,
    saveRecord
  }
  return (
    <Modal
      header={<DocumentViewHeader {...headerProps} />}
      onClickOutside={closeView}
    >
      <Formalyzer
        gadgets={activeForm.gadgets}
        logic={activeForm.logic}
        updateValue={updateData}
        value={data}
      />
    </Modal>
  )
}

function getCurrentAction (form, progress) {
  const currentProgress = last(progress)
  let foundIt = false
  for (let i = 0; i < form.process.processSteps.length; ++i) {
    if (foundIt && form.process.processSteps[i].approvable) {
      return form.process.processSteps[i]
    }
    if (form.process.processSteps[i].key === currentProgress.status) {
      foundIt = true
    }
  }
  return null
}

const ActionsHeader = styled.div`
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: row-reverse;
  height: 80px;
  ${color}
`
ActionsHeader.defaultProps = { bg: 'green' }

const ActionButton = styled.div`
  &:active {
    transform: translate3d(2px, 2px, 0);
  }
  &:hover {
    background: ${({ theme }) => theme.colors.lightgray};
  }
  background: white;
  color: ${({ theme }) => theme.colors.green};
  cursor: pointer;
  user-select: none;
  ${space}
`
ActionButton.defaultProps = { mr: 3, p: 3 }

const PopoverButton = styled.div`
  position: relative;
`

const ApproveButton = styled.div`
  &:active {
    transform: translate3d(2px, 2px, 0);
  }
  align-items: center;
  align-self: end;
  color: white;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  user-select: none;
  width: 70px;
  ${color}
`
ApproveButton.defaultProps = { bg: 'green' }

export default DocumentView
