import React from 'react'

import Text from '../../ui/text'

export const Edit = ({ config, value, onChange, hasError }) => (
  <Text
    placeholder={config.placeholder}
    value={value}
    onChange={onChange}
    hasError={hasError}
  />
)

export const renderCell = value => value

export const getDefaultFilter = () => ''

export const Filter = ({ value, onChange }) => (
  <Text placeholder='Search' value={value} onChange={onChange} />
)

export const evaluateFilter = (value, filter) => {
  return (value || '').toLowerCase().includes((filter || '').toLowerCase())
}
