import * as Sentry from '@sentry/browser'
import axios from 'axios'
import {
  cloneDeep,
  compact,
  filter,
  find,
  get,
  map,
  reverse,
  sortBy
} from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Router, Redirect } from '@reach/router'
import styled from 'styled-components'
import { space } from 'styled-system'
import { useImmer } from 'use-immer'

import DataTable from './data-table'
import DocumentView from './document-view'
import config from '../.lyzer/config.json'
import gadgets from '../formalyzer/gadgets'

const useLoadDocuments = route => {
  const [{ loading, data }, update] = useImmer({ loading: true, data: null })
  const getData = useCallback(
    () =>
      axios
        .get(`/api/${route}`)
        .then(resp => {
          update(draft => {
            draft.data = resp.data.data
            draft.loading = false
          })
        })
        .catch(error => {
          Sentry.captureException(error)
          // what should we actually do on an error?
          update(draft => {
            draft.loading = false
          })
        }),
    [route, update]
  )
  useEffect(() => {
    getData(route, update)
  }, [getData, route, update])
  const updateData = fn => {
    update(draft => {
      fn(draft.data)
    })
  }
  return [loading, data, updateData, getData]
}

export default ({ navigate, route }) => {
  const activeForm = find(config.forms, form => form.meta.route === route)
  if (!activeForm) return <Redirect noThrow to='/admin' />
  const [search, setSearch] = useState({ field: null, type: null, data: null })
  const [[sortField, sortAsc], setSort] = useState(['submitted_date', false])
  const [loading, data, updateData, refreshData] = useLoadDocuments(route)
  if (loading) return null
  const extraGadgets =
    route === 'city-councils'
      ? [
        { formKey: 'submitted_date', type: 'Date', label: 'Submission Date' },
        { formKey: 'date_to_appear', type: 'Date', label: 'Appearance Date' }
      ]
      : [
        { formKey: 'paid_on', type: 'Date', label: 'Payment Date' },
        {
          formKey: 'review_committee_date_to_appear',
          type: 'Date',
          label: 'Review Committee Date'
        },
        {
          formKey: 'zoning_meeting_date_to_appear',
          type: 'Date',
          label: 'Zoning Meeting Date'
        },
        {
          formKey: 'city_council_date_to_appear',
          type: 'Date',
          label: 'City Council Date'
        },
        { formKey: 'approved_on', type: 'Date', label: 'Approval Date' }
      ]
  const allGadgets = [...activeForm.gadgets, ...extraGadgets]
  const allHeaders = [...activeForm.headers, ...map(extraGadgets, 'formKey')]
  const hydratedHeaders = compact(
    map(allHeaders, h => find(allGadgets, { formKey: h }))
  )
  let presentationData = cloneDeep(data)
  if (sortField) presentationData = sortBy(presentationData, sortField)
  if (sortField && !sortAsc) reverse(presentationData)
  const { evaluateFilter } = get(gadgets, [search.type]) || {}
  if (evaluateFilter) {
    presentationData = filter(presentationData, datum =>
      evaluateFilter(datum[search.field], search.data)
    )
  }
  return (
    <>
      <Router>
        <DocumentView
          activeForm={activeForm}
          closeView={() => {
            refreshData()
            navigate('./')
          }}
          updateDate={(id, date) => {
            updateData(draft => {
              const doc = find(draft, { id })
              doc.date_to_appear = date
            })
          }}
          route={route}
          path=':id'
        />
      </Router>
      <ScrollWrapper>
        <FormList>
          <DataTable
            data={presentationData}
            headers={hydratedHeaders}
            onDatumClick={id => navigate(id)}
            sortField={sortField}
            sortAsc={sortAsc}
            setSort={setSort}
            search={search}
            setSearch={setSearch}
          />
        </FormList>
      </ScrollWrapper>
    </>
  )
}

const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`

const FormList = styled.div`
  height: 100%;
  width: 100%;
  ${space}
`
FormList.defaultProps = { p: 4 }
