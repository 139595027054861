import { chunk, get, map, min } from 'lodash'
import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

import gadgets from '../formalyzer/gadgets'
import Button from '../ui/button'
import { Popover, useOnOutsideClick } from '../ui/popover'

const SortIcon = styled.span`
  :after {
    content: '➜';
    margin-left: 4px;
    display: ${p => (p.show ? 'inline-block' : 'none')};
    transform: rotate(${p => (p.ascending ? 90 : -90)}deg);
  }
`

const SVG = styled.svg`
  position: relative;
  display: ${p => (p.show ? 'inline-block' : 'none')};
  top: 4px;
  margin-left: 4px;
  fill: white;
  width: 16px;
`

const FilterIcon = ({ show }) => (
  <SVG viewBox='0 0 80 90' focusable={false} show={show}>
    <path d='m 0,0 30,45 0,30 10,15 0,-45 30,-45 Z' />
  </SVG>
)

const Content = styled.div`
  background: white;
  color: black;
  position: relative;
  padding: 8px;
`

export default function ({ data = [], headers, onDatumClick, ...rest }) {
  const limit = 20
  const [page, setPage] = useState(0)
  const getNextPage = () => {
    const nextPage = Math.min(page + 1, Math.floor(data.length / limit))
    setPage(nextPage)
  }
  const getPrevPage = () => {
    setPage(Math.max(page - 1, 0))
  }
  return (
    <>
      <GridTable headers={headers}>
        <Gthead>
          <Gtr>
            {map(headers, (h, i) => (
              <Header key={i} h={h} {...rest} />
            ))}
          </Gtr>
        </Gthead>
        <Gtbody>
          {map(chunk(data, limit)[page], (d, i) => (
            <Gtr onClick={() => onDatumClick(d.id)} key={i}>
              {map(headers, (h, j) => {
                const render = gadgets[h.type].renderCell
                return <Gtd key={j}>{render ? render(d[h.formKey]) : null}</Gtd>
              })}
            </Gtr>
          ))}
        </Gtbody>
      </GridTable>
      <PagerWrapper>
        <PagerButton
          disabled={
            page === Math.floor(data.length / limit) || data.length < limit
          }
          onClick={() => getNextPage()}
        >
          ▶
        </PagerButton>
        <PagerButton disabled={page === 0} onClick={() => getPrevPage()}>
          ◀
        </PagerButton>
        <span>
          {limit * page + 1} - {min([data.length, limit * page + limit])} of{' '}
          {data.length}
        </span>
      </PagerWrapper>
    </>
  )
}

const Header = ({ h, sortAsc, sortField, setSort, search, setSearch }) => {
  const [showingPopover, setShowingPopover] = useState(false)
  const ref = useRef()
  useOnOutsideClick(ref, () => setShowingPopover(false), showingPopover)
  const { getDefaultFilter, Filter } = get(gadgets, [h.type]) || {}
  const value =
    search.field === h.formKey && search.data ? search.data : getDefaultFilter()
  return (
    <Gth
      onClick={e => {
        e.stopPropagation()
        setShowingPopover(true)
      }}
    >
      {h.label}
      <SortIcon show={sortField === h.formKey} ascending={sortAsc} />
      <FilterIcon show={search.field === h.formKey} />
      {showingPopover && (
        <Popover ref={ref}>
          <Content>
            <ButtonWrapper>
              <Button
                onClick={() => setSort([h.formKey, true])}
                active={sortField === h.formKey && sortAsc === true}
              >
                A ➜ Z
              </Button>
              <Button
                onClick={() => setSort([h.formKey, false])}
                active={sortField === h.formKey && sortAsc === false}
              >
                Z ➜ A
              </Button>
              <Button
                onClick={() => setSort([null, null])}
                active={sortField !== h.formKey || sortAsc === null}
              >
                No Sort
              </Button>
            </ButtonWrapper>
            {Filter && (
              <Filter
                value={value}
                onChange={data =>
                  setSearch({ field: h.formKey, type: h.type, data })
                }
              />
            )}
          </Content>
        </Popover>
      )}
    </Gth>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  > button {
    flex: 1;
  }
`

const GridTable = styled.table`
  display: grid;
  display: -ms-grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns: ${p =>
    map(p.headers, h => `minmax(150px, 1fr)`).join(' ')};
`

const Gthead = styled.thead`
  display: contents;
`

const Gtbody = styled.tbody`
  display: contents;
`

const Gtr = styled.tr`
  cursor: pointer;
  display: contents;
  &:nth-child(2n) > td {
    background: #ececec;
  }
`

const Gth = styled.th`
  color: white;
  &:last-child {
    border: 0;
  }
  font-size: 1.1rem;
  font-weight: normal;
  /* overflow: hidden; */
  padding: 15px;
  position: sticky;
  text-align: left;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  ${color}
  ${space}
`
Gth.defaultProps = { p: 3, bg: 'green' }

const Gtd = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${space}
`
Gtd.defaultProps = { p: 3 }

const PagerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
`

const PagerButton = styled.div`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.lightgray : theme.colors.text};
  &:hover {
    background: ${({ disabled, theme }) =>
    disabled ? 'transparent' : theme.colors.lightgray};
  }
  &:active {
    background: ${({ disabled, theme }) =>
    disabled ? 'transparent' : theme.colors.darkgray};
  }
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;
  user-select: none;
  ${space}
`
PagerButton.defaultProps = { mx: 1, p: 2 }
