import React from 'react'
import styled from 'styled-components'

export const Assembler = ({ component }) => component

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 1;
  }
`

export const Edit = ({ children }) => <Wrapper>{children}</Wrapper>
