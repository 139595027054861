import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { Router, Link, Redirect } from '@reach/router'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import Admin from './admin'
import ErrorBoundary from './error-boundary'
import theme from './theme'
import App from './user'
import Previews from './previews'
import { SnacksProvider } from './ui/snacks'

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: 'https://76f28015197544e9b25b34982a5f9c55@sentry.io/1487075'
})

const NotFound = () => (
  <>
    <h1>uh oh, not found</h1>
    <Link to='/'>Run Home Jack!</Link>
  </>
)

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    width: 100%;
  }

  * {
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
  }

  #root, #root > div {
    height: 100%;
  }
`

const el = document.getElementById('root')
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <ErrorBoundary>
        <SnacksProvider>
          <Router>
            <Redirect noThrow from='/' to='/forms' />
            <App path='forms/*' />
            <Admin path='admin/*' />
            <Previews path='preview/*' />
            <NotFound default />
          </Router>
        </SnacksProvider>
      </ErrorBoundary>
    </>
  </ThemeProvider>,
  el
)
