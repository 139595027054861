import axios from 'axios'
import { get, map, sortBy, truncate } from 'lodash'
import { Link } from '@reach/router'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

import UserContext from '../user-context'
import Button from '../ui/button'

const logout = () => {
  axios.post('/api/logout').then(() => window.location.reload())
}

export default function Sidebar ({ forms }) {
  const user = useContext(UserContext)
  return (
    <SidebarWrapper>
      <SidebarLinksWrapper>
        {map(sortBy(forms, 'meta.name'), (form, i) => (
          <SidebarLink
            active={window.location.pathname.includes(form.meta.route)}
            key={i}
            to={form.meta.route}
          >
            {form.meta.name}
          </SidebarLink>
        ))}
      </SidebarLinksWrapper>
      <UserWrapper>
        <UserInfo>
          {truncate(get(user, 'email', '').split('@')[0], { length: 20 })}
        </UserInfo>
        <Button onClick={logout}>Logout</Button>
      </UserWrapper>
    </SidebarWrapper>
  )
}

const UserWrapper = styled.div`
  align-items: center;
  background-color: rgb(25, 26, 29);
  display: flex;
  justify-content: center;
`

const UserInfo = styled.div`
  align-items: center;
  display: flex;
  height: 64px;
  ${space}
`
UserInfo.defaultProps = { p: 3 }

const SidebarWrapper = styled.div`
  background: #27292c;
  color: #c5cacf;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  max-width: 300px;
  padding-top: 35px;
  width: 300px;
`

const SidebarLinksWrapper = styled.div`
  flex: 1;
`

const SidebarLink = styled(Link)`
  display: block;
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  padding: 20px;
  border-left: ${({ active, theme }) =>
    active ? `5px solid ${theme.colors.green}` : 'none'};
  padding-left: ${({ active }) => (active ? '15px' : '20px')};
  color: ${({ active, theme }) => (active ? theme.colors.green : 'inherit')};
`
