import React from 'react'
import { StripeProvider, Elements } from 'react-stripe-elements'

import { usePreviewSource } from './use-preview-source'
import { PaymentPage } from '../user/payment'

export default () => {
  const data = usePreviewSource()
  if (!data) return null
  return (
    <StripeProvider apiKey='pk_test_4FV1w2VwAQV3UQTW2td7Pwm60082AyuGxl'>
      <Elements>
        <PaymentPage
          form={data.form}
          submitting={false}
          submitPayment={() => null}
        />
      </Elements>
    </StripeProvider>
  )
}
