import React, { useCallback, useContext, useRef } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

const SnackContext = React.createContext()

export const useSnacks = () => useContext(SnackContext)

export const SnacksProvider = ({ children }) => {
  const allSnacksRef = useRef()
  const [snacks, updateSnacks] = useImmer([])
  allSnacksRef.current = snacks
  const animate = useCallback(() => {
    if (!allSnacksRef.current.length) return
    updateSnacks(draft => {
      draft[0].show = true
    })
    setTimeout(() => {
      updateSnacks(draft => {
        draft[0].show = false
      })
    }, 3000)
    setTimeout(() => {
      updateSnacks(draft => {
        draft.splice(0, 1)
      })
      animate()
    }, 3400)
  }, [updateSnacks])
  const create = useCallback(
    message => {
      updateSnacks(draft => {
        draft.push({ message, show: false })
      })
      setTimeout(() => {
        if (allSnacksRef.current.length === 1) animate()
      })
    },
    [animate, updateSnacks]
  )
  return (
    <SnackContext.Provider value={{ create }}>
      {children}
      <Snacks>
        {!!snacks[0] && (
          <Snack show={snacks[0].show}>{snacks[0].message}</Snack>
        )}
      </Snacks>
    </SnackContext.Provider>
  )
}

const Snacks = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  height: initial !important;
  display: flex;
  justify-content: center;
`

const Snack = styled.div`
  background: black;
  position: relative;
  top: ${p => (p.show ? -20 : 60)}px;
  border-radius: 3px;
  color: white;
  display: inline-block;
  margin: 0 auto;
  padding: 16px 32px;
  transition: top 400ms;
`
