import { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

export const Popover = styled.div`
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 10px;
  top: 60px;
  ${space}
  &:before {
    content: '';
    width: 30px;
    height: 30px;
    background: white;
    box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.15);
    display: block;
    position: absolute;
    top: -15px;
    left: calc(50% - 15px);
    transform: rotate(45deg);
  }
`

// https://bugs.chromium.org/p/chromium/issues/detail?id=944263
let startTarget = null
export const useOnOutsideClick = (ref, fn, run) => {
  useLayoutEffect(() => {
    if (!run) return
    const func = e => {
      if (e.button !== 0) return
      if (startTarget === e.target || !isInputElement(startTarget)) {
        if (ref && ref.current && !ref.current.contains(e.target)) fn(e)
      }
      startTarget = null
    }
    const mousedownFunc = e => {
      if (e.button !== 0) return
      startTarget = e.target
    }
    document.addEventListener('click', func)
    document.addEventListener('mousedown', mousedownFunc)
    return () => {
      document.removeEventListener('click', func)
      document.removeEventListener('mousedown', mousedownFunc)
    }
  }, [fn, ref, run])
}

const isInputElement = el => {
  if (!el) return false
  var name = el.nodeName.toLowerCase()
  return (
    name === 'textarea' ||
    (name === 'input' &&
      /^(?:text|email|number|search|tel|url|password)$/i.test(el.type))
  )
}
