import { Redirect } from '@reach/router'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { find } from 'lodash'
import React, { useEffect, useState } from 'react'
import qs from 'querystring'

import Form from './form'
import config from '../.lyzer/config.json'

const useLoadDocument = (route, id, token) => {
  const [[loading, document], setState] = useState([true, null])
  useEffect(() => {
    axios
      .get(`/api/${route}/${id}`, {
        headers: { 'x-formal-email-token': token }
      })
      .then(resp => {
        setState([false, resp.data.data])
      })
      .catch(error => {
        Sentry.captureException(error)
        // what should we actually do on an error?
        setState([false, null])
      })
  }, [id, route, token])
  return [loading, document]
}

export default props => {
  const form = find(config.forms, form => form.meta.route === props.route)
  if (!form) return <Redirect noThrow to='/forms' />
  const token = qs.parse(window.location.search.slice(1)).token
  const [loading, document] = useLoadDocument(props.route, props.id, token)
  if (loading) return null
  return <Form {...props} data={document} token={token} />
}
