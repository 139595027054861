export default {
  spacing: [0, 4, 8, 16, 32, 64, 128, 256],
  colors: {
    black: '#000000',
    lightgray: '#ECECEC',
    lightgray2: '#DEDEDE',
    darkgray: '#7E7E7E',
    green: '#749E97', // #1AC5A8
    red: '#E15454',
    hoverGreen: '#62847e',
    blue: '#439FBC',
    text: '#27292C'
  }
}
