import React from 'react'
import styled from 'styled-components'

const StyledSelect = styled.select`
  width: 100%;
  padding: 8px;
  height: 42px;
  border: 1px solid ${p => (p.hasError ? p.theme.colors.red : '#c5cacf')};
`

export const Option = styled.option``

export const Select = React.forwardRef(({ onChange, ...rest }, ref) => (
  <StyledSelect ref={ref} onChange={e => onChange(e.target.value)} {...rest} />
))
