import { Redirect } from '@reach/router'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { find } from 'lodash'
import React, { useState } from 'react'
import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe
} from 'react-stripe-elements'
import styled, { keyframes } from 'styled-components'
import { color, space } from 'styled-system'

import Info from './lib/info'
import { ReactComponent as Logo } from './lib/logo.svg'
import Button from '../ui/button'
import { useSnacks } from '../ui/snacks'
import config from '../.lyzer/config.json'

export default props => {
  const form = find(config.forms, form => form.meta.route === props.route)
  if (!form) return <Redirect noThrow to='/forms' />
  return (
    <StripeProvider apiKey='pk_test_4FV1w2VwAQV3UQTW2td7Pwm60082AyuGxl'>
      <Elements>
        <Page {...props} form={form} />
      </Elements>
    </StripeProvider>
  )
}

const Page = injectStripe(({ route, navigate, id, form, stripe }) => {
  const [submitting, setSubmitting] = useState(false)
  const snack = useSnacks()
  const submitPayment = async () => {
    setSubmitting(true)
    const resp = await stripe.createToken()
    if (resp.error) {
      snack.create(resp.error.message || 'Invalid Card Information')
      setSubmitting(false)
      return
    }
    axios
      .post(`/api/${route}/${id}/payment`, { tokenId: resp.token.id })
      .then(resp => navigate(`/forms/${route}/confirmation`))
      .catch(error => {
        setSubmitting(false)
        if (error.response.status === 402) {
          snack.create(error.response.data)
        } else {
          snack.create('An unexpected error has occurred. Please try again.')
          Sentry.captureException(error)
        }
      })
  }
  return (
    <PaymentPage
      submitPayment={submitPayment}
      submitting={submitting}
      form={form}
    />
  )
})

export const PaymentPage = ({ submitPayment, form, submitting }) => (
  <Wrapper>
    <Flex>
      <Info />
      <Logo />
    </Flex>
    <Title mt={2} mb={3}>
      {form.meta.name}
    </Title>
    <Box>
      <Subtitle pt={3} pb={4}>
        Payment Required
      </Subtitle>
      <Text py={3} mx='auto'>
        This form has an associated fee of{' '}
        <b>{format(form.process.paymentAmount)}</b>. Once paid, your submission
        will be processed.
      </Text>
      <StripeStyles>
        <CardElement />
      </StripeStyles>
    </Box>
    <RightArea>
      <SubmitButton disabled={submitting} onClick={submitPayment}>
        {submitting ? (
          <span>
            Submitting <Loader />
          </span>
        ) : (
          'Submit Payment'
        )}
      </SubmitButton>
    </RightArea>
  </Wrapper>
)

const format = amount => {
  const num = parseInt(amount, 10)
  const dollars = Math.floor(num / 100)
  let pennies = '' + (num % 100)
  while (pennies.length < 2) pennies = `0${pennies}`
  return `$${dollars}.${pennies}`
}

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 32px;
  max-width: 768px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Box = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 193px);
  ${color}
  ${space}
`
Box.defaultProps = { p: 4, bg: 'lightgray' }

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px dashed;
  border-radius: 20px;
  animation: ${rotate} 2s linear infinite;
  position: absolute;
  margin-left: 8px;
  margin-top: -2px;
`

const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  ${space}
`

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  ${space}
`

const Text = styled.div`
  font-size: 14px;
  max-width: 519px;
  ${space}
`

const RightArea = styled.div`
  @media (min-width: 950px) {
    position: fixed;
    bottom: 16px;
    right: 16px;
    min-width: calc((100vw - 768px) / 2 - 32px);
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
`

const StripeStyles = styled.div`
  background: white;
  padding: 20px;
`
