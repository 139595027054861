import { get } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { SingleDatePicker, DateRangePicker } from 'react-dates'
import styled from 'styled-components'

const today = moment().startOf('day')
const fiveFromNow = today.add(5, 'days')
const valid = [1, 2, 3, 4, 5, 6, 7, 15, 16, 17, 18, 19, 20, 21]
const isValidDate = date => date.day() === 3 && valid.includes(date.date())
const getFirstValidDate = () => {
  const m = fiveFromNow.clone()
  while (!isValidDate(m)) m.add(1, 'days')
  return m
}

const filters = {
  none: {
    isDayBlocked: undefined,
    isOutsideRange: undefined,
    firstValidDate: () => undefined
  },
  everyOtherWednesday: {
    isDayBlocked: date => !isValidDate(date),
    isOutsideRange: date => date.isBefore(fiveFromNow),
    firstValidDate: getFirstValidDate
  }
}

const Wrapper = styled.div`
  .SingleDatePickerInput__withBorder {
    border: 1px solid ${p => (p.hasError ? p.theme.colors.red : '#c5cacf')};
  }
`

export const Edit = ({ config, value, onChange, hasError }) => {
  const [focused, setFocused] = useState(false)
  const filter = get(filters, config.filterKey, filters.none)
  return (
    <Wrapper hasError={hasError}>
      <SingleDatePicker
        date={value ? moment(value) : null}
        onDateChange={onChange}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id='someId'
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        readOnly
        block
        isDayBlocked={filter.isDayBlocked}
        isOutsideRange={filter.isOutsideRange}
      />
    </Wrapper>
  )
}

export const initialValue = config => {
  const filter = get(filters, config.filterKey, filters.none)
  return filter.firstValidDate()
}

export const renderCell = value =>
  value ? moment(value).format('MM/DD/YYYY') : null

export const getDefaultFilter = () => ({ startDate: null, endDate: null })

export const Filter = ({ value, onChange }) => {
  const [focused, setFocused] = useState(false)
  return (
    <DateRangePicker
      startDate={value.startDate || null}
      startDateId='startId'
      endDate={value.endDate || null}
      endDateId='endId'
      onDatesChange={onChange}
      focusedInput={focused}
      onFocusChange={focused => setFocused(focused)}
      hideKeyboardShortcutsPanel
      readOnly
      showClearDates
      isOutsideRange={() => false}
    />
  )
}

export const evaluateFilter = (value, { startDate, endDate }) => {
  if (!startDate || !endDate) return true
  if (!value) return false
  return moment(value).isBetween(startDate, endDate)
}
