import { Redirect, Router } from '@reach/router'
import { filter, sortBy } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import DocumentList from './document-list'
import Sidebar from './sidebar'
import AuthWrapper from '../auth-wrapper'
import config from '../.lyzer/config.json'

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  > div {
    flex: 1;
  }
`

let forms = config.forms
forms = filter(forms, 'meta.enabled')
forms = sortBy(forms, 'meta.name')

export default function AdminRouter () {
  return (
    <AuthWrapper>
      <Wrapper>
        <Sidebar forms={forms} />
        <Router>
          <Redirect noThrow from='/' to={`admin/${forms[0].meta.route}`} />
          <DocumentList path=':route/*' />
        </Router>
      </Wrapper>
    </AuthWrapper>
  )
}
