import { Redirect } from '@reach/router'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { find, last } from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import styled from 'styled-components'
import { color, space, width } from 'styled-system'
import qs from 'querystring'

import Info from './lib/info'
import { ReactComponent as Logo } from './lib/logo.svg'
import config from '../.lyzer/config.json'
import A from '../ui/a'

const Step = ({ title1, title2, date }) => (
  <StepWrapper>
    <OuterBox>
      <InnerBox complete={date} />
    </OuterBox>
    <div>
      <div>{date ? title2 : title1}</div>
      {date && <div>{moment(date).format('MM/DD/YYYY')}</div>}
    </div>
  </StepWrapper>
)

const useLoadProgress = (route, id) => {
  const [[loading, document], setState] = useState([true, null])
  useEffect(() => {
    const token = qs.parse(window.location.search.slice(1)).token
    axios
      .get(`/api/${route}/${id}/progress`, {
        headers: { 'x-formal-email-token': token }
      })
      .then(resp => {
        setState([false, resp.data.data])
      })
      .catch(error => {
        Sentry.captureException(error)
        // what should we actually do on an error?
        setState([false, null])
      })
  }, [id, route])
  return [loading, document]
}

export default ({ route, id }) => {
  const form = find(config.forms, form => form.meta.route === route)
  if (!form) return <Redirect noThrow to='/forms' />
  const [loading, progress] = useLoadProgress(route, id)
  if (loading) return null
  if (!progress) return <Redirect noThrow to='/forms' />
  return <ProgressPage form={form} progress={progress} />
}

export const ProgressPage = ({ form, progress }) => {
  const currentProgress = last(progress)
  const currentStep = find(form.process.processSteps, {
    key: currentProgress.status
  })
  const description = currentStep.description.replace(
    /{{([\s\S]+?)}}/g,
    moment(currentProgress.action_date).format('M/D/YYYY')
  )
  return (
    <Container>
      <Wrapper>
        <Flex p={2}>
          <Info color='white' />
          <Circle>
            <Logo />
          </Circle>
        </Flex>
        <Title mb={2}>{form.meta.name}</Title>
        <Box>
          <ScrollOuter>
            <ScrollInner>
              <SVG>
                <Line x1={0} y1={10} x2={400} y2={10} />
              </SVG>
              <Line />
              <Flex width={493} py={5}>
                {form.process.processSteps.map(step => (
                  <Step
                    key={step.key}
                    date={(find(progress, { status: step.key }) || {}).date}
                    title1={step.unfinishedStatus}
                    title2={step.finishedStatus}
                  />
                ))}
              </Flex>
            </ScrollInner>
          </ScrollOuter>
          <Markdown>
            <ReactMarkdown renderers={markdownOverrides}>
              {description}
            </ReactMarkdown>
          </Markdown>
        </Box>
      </Wrapper>
    </Container>
  )
}

const markdownOverrides = {
  heading: props =>
    props.level === 1 ? (
      <Big {...props} />
    ) : props.level === 2 ? (
      <Subtitle pt={3} pb={4} {...props} />
    ) : (
      React.createElement(
        `h${props.level}`,
        props['data-sourcepos']
          ? { 'data-sourcepos': props['data-sourcepos'] }
          : {},
        props.children
      )
    ),
  strong: props => <Span color='green' {...props} />,
  link: props => <A {...props} />,
  paragraph: props => <Text py={3} mx='auto' {...props} />
}

const Markdown = styled.div`
  ${space}
`
Markdown.defaultProps = { p: 2 }

const ScrollOuter = styled.div`
  @media (max-width: 768px) {
    align-items: center;
    display: flex;
    overflow: scroll;
  }
`

const ScrollInner = styled.div`
  @media (max-width: 768px) {
    width: 493px;
  }
`

const SVG = styled.svg`
  @media (max-width: 767px) {
    left: 45px;
  }
  @media (min-width: 768px) {
    margin: 0 auto;
  }
  display: block;
  height: 20px;
  position: relative;
  top: 95px;
  width: 400px;
`

const Line = styled.line`
  stroke: ${p => p.theme.colors.green};
  stroke-width: 7px;
  stroke-dasharray: 20px;
  stroke-dashoffset: 9px;
`

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  ${color}
`
Container.defaultProps = { bg: 'green' }

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 16px;
  max-width: 768px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  ${width}
  ${space}
`

const Box = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 193px);
  @media (min-width: 768px) {
    padding: ${({ theme }) => theme.spacing[4]}px;
  }
  ${color}
`
Box.defaultProps = { bg: 'white' }

const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  ${space}
`

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  ${space}
`

const Text = styled.div`
  font-size: 14px;
  ${space}
`

const Circle = styled.div`
  width: 165px;
  height: 165px;
  border-radius: 165px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const Span = styled.span`
  font-weight: bold;
  ${color}
`

const Big = styled.span`
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
  ${color}
`

const OuterBox = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 8px;
  background: ${p => p.theme.colors.green};
  position: relative;
`

const InnerBox = styled.div`
  position: relative;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: ${p => (p.complete ? p.theme.colors.green : 'white')};
  border: 3px solid white;
`

const StepWrapper = styled.div`
  font-size: 14px;
  text-align: center;
  height: 100px;
  width: 100px;
`
