import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import Login from './login'
import UserContext from './user-context'

export default ({ children }) => {
  const [user, setUser] = useState({})
  useEffect(() => {
    if (user.id) return
    axios
      .get('/api/me')
      .then(res => {
        setUser(res.data)
      })
      .catch(err => {
        if (err.response.status === 401) {
          setUser('error')
        }
      })
  }, [user.id])
  if (user === 'error') return <Login setUser={setUser} />
  if (!isEmpty(user)) {
    Sentry.configureScope(scope => {
      scope.setUser(user)
    })
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
  }
  return <div>Loading</div>
}
