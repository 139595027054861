import React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

export default ({ children, onClickOutside, header }) => {
  return (
    <ShadowBox onClick={onClickOutside}>
      <CloseButton onClick={onClickOutside}>X Close</CloseButton>
      <ModalWrapper onClick={e => e.stopPropagation()}>
        {header}
        <ModalContent>{children}</ModalContent>
      </ModalWrapper>
    </ShadowBox>
  )
}

const ModalWrapper = styled.div`
  background: #fff;
  margin: 50px auto;
  width: 60%;
  z-index: 3;
`

const ShadowBox = styled.div`
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: scroll;
  width: 100%;
  z-index: 2;
`

const ModalContent = styled.div`
  ${space}
  ${color}
`
ModalContent.defaultProps = { bg: 'lightgray', p: 4 }

const CloseButton = styled.div`
  color: white;
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  right: 5%;
  top: 50px;
`
