import { Link } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import { filter, sortBy } from 'lodash'

import config from '../.lyzer/config.json'

let forms = config.forms
forms = filter(forms, 'meta.enabled')
forms = sortBy(forms, 'meta.name')

export default () => (
  <Wrapper>
    <H1>Salem City Request Forms</H1>
    <UL>
      {forms.map(form => (
        <LI key={form.id}>
          <A to={form.meta.route}>{form.meta.name}</A>
        </LI>
      ))}
    </UL>
  </Wrapper>
)

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 768px;
`
const H1 = styled.h1`
  text-align: center;
`
const UL = styled.ul`
  list-style-type: none;
  padding: 0;
`
const LI = styled.li`
  margin: 16px 8px;
`
const A = styled(Link)`
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  height: 40px;
  text-decoration: none;
  ${color}
  ${space}
  &:hover {
    background: ${p => p.theme.colors.lightgray2};
  }
`
A.defaultProps = { pl: 3, bg: 'lightgray', color: 'blue' }
