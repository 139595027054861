import React from 'react'

import { Select, Option } from '../../ui/dropdown'

export const Edit = ({ config, value, onChange }) => (
  <Select value={value} onChange={onChange}>
    {config.options.map(option => (
      <Option key={option.id} value={option.id}>
        {option.label}
      </Option>
    ))}
  </Select>
)

export const initialConfig = () => ({ options: [] })
