import { Router } from '@reach/router'
import React from 'react'

import ConfirmationPreview from './confirmation'
import FormPreview from './form'
import PaymentPreview from './payment'
import ProgressPreview from './progress'
import AuthWrapper from '../auth-wrapper'

export default function AdminRouter () {
  return (
    <AuthWrapper>
      <Router>
        <ConfirmationPreview path='confirmation' />
        <FormPreview path='form' />
        <PaymentPreview path='payment' />
        <ProgressPreview path='progress' />
      </Router>
    </AuthWrapper>
  )
}
