import React from 'react'
import styled from 'styled-components'
import { color } from 'styled-system'

const Wrapper = styled.div``
const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  ${color}
`
const P = styled.div`
  font-weight: bold;
  font-size: 12px;
  ${color}
`

export default ({ color = 'inherit' }) => (
  <Wrapper>
    <Title color={color}>Salem City</Title>
    <P color={color}>30 West 100 South Salem, Utah 84653</P>
    <P color={color}>801-423-2770, Fax 801-423-2818,</P>
    <P color={color}>www.salemcity.org</P>
  </Wrapper>
)
