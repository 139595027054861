import React from 'react'

import { usePreviewSource } from './use-preview-source'
import { ProgressPage } from '../user/progress'

export default () => {
  const data = usePreviewSource()
  if (!data) return null
  return <ProgressPage form={data.form} progress={data.progress} />
}
