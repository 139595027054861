import React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

export const Edit = ({ value, onChange }) => (
  <input
    type='checkbox'
    checked={value}
    onChange={e => onChange(e.target.checked)}
  />
)

export const initialValue = () => false

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${space}
`
Container.defaultProps = { p: 2 }

const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  ${color}
`
Label.defaultProps = { color: 'darkgray' }

export const Assembler = ({ label, description, component }) => (
  <Container>
    {component}
    {label && <Label>{label}</Label>}
  </Container>
)
