import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

const Input = styled.input.attrs({ type: 'text' })`
  width: 100%;
  padding: 8px;
  height: 42px;
  border: 1px solid ${p => (p.hasError ? p.theme.colors.red : '#c5cacf')};
  ${space}
`

export default React.forwardRef(({ onChange, ...rest }, ref) => (
  <Input
    ref={ref}
    onChange={onChange && (e => onChange(e.target.value))}
    {...rest}
  />
))
