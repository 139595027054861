import styled from 'styled-components'
import { color, space } from 'styled-system'

const A = styled.a`
  display: block;
  font-size: 14px;
  text-decoration: underline;
  ${color}
  ${space}
`
A.defaultProps = { color: 'blue' }

export default A
