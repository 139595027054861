import { find, forEach, get, isFunction, set } from 'lodash'
import React from 'react'
import uuid from 'uuid/v4'

import DefaultAssembler from './default-assembler'
import Gadgets from './gadgets'
import * as logicFns from './logic'

export default ({ logic, gadgets, value, updateValue, errorKeys = [] }) => {
  if (!logic) return null
  const form = logicFns.mapLogic(logic, (alpha, children) => {
    const gadget = find(gadgets, { alpha })
    const Gadget = Gadgets[gadget.type] || Gadgets.Generic
    const Assembler = Gadget.Assembler || DefaultAssembler
    let fieldHandlers = {}
    if (gadget.formKey) {
      fieldHandlers.value = get(value, gadget.formKey)
      fieldHandlers.onChange = valOrFn =>
        updateValue(draft => {
          const updateSubValue = isFunction(valOrFn) ? valOrFn : () => valOrFn
          const curValue = get(draft, gadget.formKey)
          const newValue = updateSubValue(curValue)
          if (newValue !== undefined) set(draft, gadget.formKey, newValue)
        })
    }
    const hasError = gadget.formKey && errorKeys.includes(gadget.formKey)
    return (
      <Assembler
        hasError={hasError}
        label={gadget.label}
        // description={gadget.description}
        component={
          <Gadget.Edit
            type={gadget.type}
            hasError={hasError}
            config={gadget.config}
            {...fieldHandlers}
            children={children}
          />
        }
      />
    )
  })
  return <div>{form}</div>
}

export const buildDefaultValue = gadgets => {
  const value = { id: uuid(), status: 'submitted' }
  forEach(gadgets, gadget => {
    if (gadget.formKey && gadget.type !== 'HelpText') {
      const Gadget = Gadgets[gadget.type] || Gadgets.Generic
      const initialValue = Gadget.initialValue
        ? Gadget.initialValue(gadget.config)
        : ''
      set(value, gadget.formKey, initialValue)
    }
  })
  return value
}

export const validateGadgets = (gadgets, value) => {
  const errors = []
  forEach(gadgets, gadget => {
    if (gadget.formKey) {
      const Gadget = Gadgets[gadget.type] || Gadgets.Generic
      if (Gadget.validate) {
        const message = Gadget.validate(
          get(value, gadget.formKey),
          gadget.label || gadget.formKey
        )
        if (message) errors.push({ formKey: gadget.formKey, message })
      }
    }
  })
  return errors
}
