import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

import config from '../.lyzer/config'
import Button from '../ui/button'
import Input from '../ui/text'

export default ({ setUser }) => {
  const ref = useRef()
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const tryLogin = async e => {
    e.preventDefault()
    setIsLoggingIn(true)
    setLoginError(false)
    try {
      const resp = await axios.post('/api/auth', { email, password })
      if (resp.status === 200) {
        const { data } = await axios.get('/api/me')
        setUser(data)
      }
    } catch (err) {
      setLoginError(true)
      setIsLoggingIn(false)
      ref.current.focus()
    }
  }

  return (
    <LoginWrapper>
      <LoginCard>
        <LoginHeader>Login to {config.meta.name}</LoginHeader>
        <LoginBody>
          {loginError && (
            <ErrorMessage>An error occurred while logging in</ErrorMessage>
          )}
          <form>
            <label>Email address</label>
            <Input
              ref={ref}
              autoFocus
              mb={3}
              disabled={isLoggingIn}
              onChange={setEmail}
              type='text'
              value={email}
            />
            <label>Password</label>
            <Input
              mb={3}
              style={{ display: 'block' }}
              disabled={isLoggingIn}
              onChange={setPassword}
              type='password'
              value={password}
            />
            <Button
              disabled={isEmpty(email) || isEmpty(password) || isLoggingIn}
              onClick={tryLogin}
              onSubmit={tryLogin}
              type='submit'
              width={1}
            >
              LOGIN
            </Button>
          </form>
        </LoginBody>
      </LoginCard>
    </LoginWrapper>
  )
}

const ContentCentered = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const LoginWrapper = styled(ContentCentered)`
  height: 100%;
  width: 100%;
  ${color}
`
LoginWrapper.defaultProps = { bg: 'green' }

const LoginCard = styled.div`
  background: white;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
`

const LoginHeader = styled(ContentCentered)`
  border-bottom: 1px solid black;
  ${space}
`
LoginHeader.defaultProps = { py: 3 }

const LoginBody = styled(ContentCentered)`
  flex-direction: column;
  ${space}
`
LoginBody.defaultProps = { px: 5, py: 4 }

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.red};
  ${space}
`
ErrorMessage.defaultProps = { mb: 3 }
