import { useEffect, useState } from 'react'

export const usePreviewSource = () => {
  const [data, setData] = useState(null)
  useEffect(() => {
    window.addEventListener('message', e => {
      const { data, source } = e.data
      if (source !== 'lyzer') return
      setData(data)
    })
    window.opener.postMessage(
      { source: 'lyzer-preview', message: 'ready' },
      '*'
    )
  }, [])
  return data
}
