import React from 'react'

import { usePreviewSource } from './use-preview-source'
import { FormPage } from '../user/form'

export default () => {
  const data = usePreviewSource()
  if (!data) return null
  return <FormPage form={data.form} />
}
