import axios from 'axios'
import { find, findIndex, forEach, map, some } from 'lodash'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import shortid from 'shortid'
import styled, { keyframes } from 'styled-components'

import { useSnacks } from '../../ui/snacks'

const getExtension = name => {
  const parts = (name || '.').split('.') || []
  return parts.pop() || 'file'
}

export const initialValue = () => []

export const validate = (value, label) => {
  if (some(value, 'unfinished')) {
    return `Please wait for the files in ${label} to finish uploading`
  }
}

export const Edit = ({ value, onChange }) => {
  const snack = useSnacks()
  const { isDragActive, getRootProps, getInputProps } = useDropzone()
  const upload = useCallback(
    files => {
      forEach(files, async file => {
        const id = shortid.generate()
        onChange(draft => {
          draft.push({ id, name: file.name, unfinished: true })
        })
        const resp = await axios.post('/api/presign', { path: file.name })
        // maybe hook in to onUploadProgress?
        axios
          .put(resp.data.url, file)
          .then(() => {
            onChange(draft => {
              const item = find(draft, { id })
              delete item.unfinished
              item.id = `${resp.data.uuid}/${item.name}`
            })
          })
          .catch(() => {
            onChange(draft => {
              const i = findIndex(draft, { id })
              draft.splice(i, 1)
            })
            snack.create('Something went wrong. Please try again.')
          })
      })
    },
    [onChange, snack]
  )
  const onDrop = useCallback(e => upload(e.dataTransfer.files), [upload])
  const onClick = useCallback(e => upload(e.target.files), [upload])
  return (
    <Container>
      <DropContainer {...getRootProps({ isDragActive, onDrop })}>
        <input {...getInputProps({ onChange: onClick })} />
        {value.length ? (
          <Flex>
            {map(value, file => (
              <File
                key={file.id}
                href={file.url}
                target='_blank'
                onClick={e => e.stopPropagation()}
              >
                <Main>{getExtension(file.name)}</Main>
                {file.unfinished && <Loader />}
                <Sub>{file.name}</Sub>
              </File>
            ))}
          </Flex>
        ) : (
          <p>Drag 'n' drop files here, or click to select files</p>
        )}
      </DropContainer>
    </Container>
  )
}

const Container = styled.div``

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  border: 5px dashed;
  border-radius: 100px;
  animation: ${rotate} 2s linear infinite;
`

const DropContainer = styled.div`
  padding: 50px;
  color: #99a5ab;
  transition: all 200ms;
  border: 3px dashed #99a5ab;
  background: ${props => (props.isDragActive ? '#ccc' : '#e4e5e9')};
  font-size: 30px;
  text-align: center;
`

const Flex = styled.div`
  display: flex;
  overflow: auto;
`

const File = styled.a`
  width: 140px;
  height: 170px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 8px;
  flex-shrink: 0;
  text-decoration: none;
  color: #99a5ab;
`

const Main = styled.div`
  color: #c5cacf;
  opacity: 0.5;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
`

const Sub = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre;
  width: 100%;
  overflow: hidden;
`
