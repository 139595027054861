import { Router } from '@reach/router'
import React from 'react'

import Confirmation from './confirmation'
import Form from './form'
import List from './list'
import Payment from './payment'
import Progress from './progress'
import Edit from './edit'

export default () => (
  <Router>
    <List path='/' />
    <Form path=':route' />
    <Confirmation path=':route/confirmation' />
    <Payment path=':route/:id/payment' />
    <Progress path=':route/:id/progress' />
    <Edit path=':route/:id/edit' />
  </Router>
)
